import React, { useContext, useEffect } from 'react'
import { Button } from 'antd'
import { ConfigContext } from '../config/ConfigContext'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import { css, SerializedStyles } from '@emotion/react'
import usePickupDestination from './destinations/usePickupDestination'
import { DestinationsContext } from './destinations/Destinations'

const cta = css`
  background-color: #ec781d; //orange
  font-size: 18px;
  font-weight: 500;
  padding: 3px;
  width: 250px;
  border: none;

  &:hover {
    background-color: #ff9933; //light orange
  }

  &:active,
  &:focus {
    background-color: #ec781d; //orange
  }

  @media (max-width: 768px) {
    font-size: 16px;
    width: 150px;
  }

  @media (max-width: 480px) {
    width: 120px;
  }
`

type GetStartedButtonProps = {
  css: SerializedStyles
  destinationsState: any
}

const GetStartedButton = ({ css: instCss, destinationsState, ...props }: GetStartedButtonProps) => {
  const configContext = useContext(ConfigContext)
  const [campaign] = useQueryParam('campaign', NumberParam)
  const [vehicle] = useQueryParam('vehicle', NumberParam)
  const [utm_code] = useQueryParam('utm_code', StringParam)

  const params = new URLSearchParams()
  const defaultDest = usePickupDestination(destinationsState)

  if (campaign && !isNaN(campaign)) params.append('campaign', campaign.toString())
  if (vehicle && !isNaN(vehicle)) params.append('vehicle', vehicle.toString())
  if (defaultDest) params.append('dest', defaultDest.value)
  if (utm_code) params.append('utm_code', utm_code);


  // TODO SC: don't load until ipinfo gets a fair chance to return (timeout?)
  return (
    <Button
      type="primary"
      css={css`
        ${cta} ${instCss}
      `}
      {...props}
      // TODO SC: Supply source homepage
      onClick={() =>
        (window.location = `${configContext.baseAppUrl}/createtrip?${params.toString()}`)
      }
    >
      Get Started
    </Button>
  )
}

const GetStartedButtonWrapped = (props: any) => {
  return (
    <DestinationsContext.Consumer>
      {value => <GetStartedButton {...props} destinationsState={value} />}
    </DestinationsContext.Consumer>
  )
}

export default GetStartedButtonWrapped
