// see https://www.smashingmagazine.com/2020/07/custom-react-hook-fetch-cache-data/
import { useEffect, useReducer, useRef } from 'react'
import axios, { AxiosResponse } from 'axios'

export interface FetchState {
  status: string
  error: any
  data: any
}

export const useFetchCached = (path: string): FetchState => {
  const cache = useRef({})
  const initialState: FetchState = {
    status: 'idle',
    error: null,
    data: []
  }

  const [state, dispatch] = useReducer((state: any, action: any) => {
    switch (action.type) {
      case 'FETCHING':
        return { ...initialState, status: 'fetching' }
      case 'FETCHED':
        return { ...initialState, status: 'fetched', data: action.payload }
      case 'FETCH_ERROR':
        return { ...initialState, status: 'error', error: action.payload }
      default:
        return state
    }
  }, initialState)

  useEffect(() => {
    let cancelRequest = false
    if (!path) return
    const url = `${window.location.origin}/${path}`

    const fetchData = async () => {
      dispatch({ type: 'FETCHING' })
      if (cache.current[path]) {
        const data = cache.current[path]
        dispatch({ type: 'FETCHED', payload: data })
      } else {
        try {
          const response: AxiosResponse = await axios({
            method: 'get',
            url
          })

          const data = await response.data
          cache.current[path] = data
          if (cancelRequest) return
          dispatch({ type: 'FETCHED', payload: data })
        } catch (error) {
          if (cancelRequest) return
          dispatch({ type: 'FETCH_ERROR', payload: error.message })
        }
      }
    }

    fetchData()

    return function cleanup() {
      cancelRequest = true
    }
  }, [path])

  return state
}
